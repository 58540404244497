import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jricher/Projects/oauth.xyz-site/src/components/layout.js";
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Code from '../components/code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Discovery" keywords={['oauth', 'authorization', 'security']} mdxType="SEO" />
    <h1>{`Discovery`}</h1>
    <p>{`With its negotiation-based design, GNAP minimizes the need for the client instance to do any pre-flight discovery. To start a `}<Link to="/request" mdxType="Link">{`request`}</Link>{`, the client instance only needs to know the single endpoint of the AS. Everything else can be negotiated dynamically. For example, the client indicates all of its supported interaction modes for a given request, and the AS responds to whichever ones from that set that it supports with the appropriate information for that interaction mode such as any URLs the client instance needs to send the user to. Even `}<Link to="/continue" mdxType="Link">{`continuation of an ongoing request`}</Link>{` uses access tokens and URLs that are given to the client instance in `}<Link to="/response" mdxType="Link">{`response to the first request`}</Link>{`.`}</p>
    <p>{`Even with this design in mind, GNAP does include some optional discovery mechanisms to allow components to optimize their configurations.`}</p>
    <h2>{`Client-AS Discovery`}</h2>
    <p>{`The client can send an HTTP `}<inlineCode parentName="p">{`OPTIONS`}</inlineCode>{` request to the AS endpoint.`}</p>
    <Code from="client" to="as" language='http' codeString={`OPTIONS /transaction HTTP/1.1
Host: server.example.com`} mdxType="Code" />
    <p>{`This returns a JSON document describing the server's supported features. `}</p>
    <Code from="as" to="client" language='json' code={{
      grant_request_endpoint: "https://server.example.com/tx",
      interaction_start_modes_supported: ["redirect", "user_code", "app"],
      interaction_finish_methods_supported: ["redirect", "push"],
      key_proofs_supported: ["httpsig", "mtls", "jwsd"],
      subject_formats_supported: ["opaque", "email"],
      assertions_supported: ["id_token"]
    }} mdxType="Code" />
    <p>{`Note that the OPTIONS discovery request is meant to be unauthenticated.`}</p>
    <p>{`Clients can use this information to pre-configure an optimized request to the AS, if desired. However, it's not expected that most clients will need to do this kind of optimization as it costs an extra round trip.`}</p>
    <p>{`Also note that presence of a value in the discovery document is not a guarantee that a client can use that feature. An AS is not obligated to allow any particular client or transaction request to use a given feature. For example, a client might be registered with a particular key proofing type and can therefore only use that type, even if the server supports others.`}</p>
    <h2>{`Client-RS Discovery`}</h2>
    <p>{`If a client instance calls a GNAP-protected RS without an access token, the RS can point the client to the appropriate AS to use for its request using a `}<inlineCode parentName="p">{`WWW-Authenticate`}</inlineCode>{` header. This header points to the AS endpoint, which the client instance can call with a `}<Link to="/request" mdxType="Link">{`request for access`}</Link>{`.`}</p>
    <Code from="rs" to="client" language="http" codeString={`HTTP 401 Not Authorized
WWW-Authenticate: GNAP as_uri=https://server.example/tx,access=FWWIKYBQ6U56NL1`} mdxType="Code" />
    <p>{`This response can also include a string that the client instance can use in its access token request as a reference value in the `}<inlineCode parentName="p">{`access`}</inlineCode>{` array. This reference is meant to ensure the client will be able to access the resource it first tried to access. Since the original request was made without the context provided by an access token, this value represent's the RS's best guess as to what the client was asking for. The value can even be a dynamically-registered access reference value, if the RS and AS communicate at runtime, similar to UMA's permission registration system.`}</p>
    <h2>{`RS-AS Discovery`}</h2>
    <p>{`The AS can offer several functions that face RS's being protected, and to facilitate dynamic and programmatic configuration of these functions, the AS can offer a discovery document at a location based on the request endpoint's host with `}<inlineCode parentName="p">{`.well-known/gnap-as-rs`}</inlineCode>{` as its path.`}</p>
    <Code from="as" to="rs" code={{
      grant_request_endpoint: "https//server.example.com/tx",
      introspection_endpoint: "https://server.example.com/introspect",
      resource_registration_endpoint: "https://server.example.com/resource",
      token_formats_supported: ["jwt", "paseto"]
    }} mdxType="Code" />
    <p>{`This is separated from the Client-AS discovery above because it's not expected that a client would ever see such a discovery document.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      